import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/no/analyse-rådgivning",
  "Data Lag": "/no/data-lag"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Lag forklart med Tutorial [2024]"
        description="Et data lag er det grunnleggende konseptet for enhver profesjonell webanalyseoppsett. Men hva er det egentlig og hvordan implementerer vi det?"
        lang="no"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        canonical="/no/data-lag"
        datePublished="2024-06-12T04:32:43.188Z"
        dateModified="2024-06-12T04:32:43.188Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualisering av Google Tag Manager Data Layer i nettleserkonsollen"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Lag</H>
        <p>
          I forbindelse med tag management og webanalyse har du kanskje hørt begrepet <b>data lag</b>. Det er det
          grunnleggende elementet i et ambisiøst webanalyseoppsett fordi alle datapunkter og sporingsregler avhenger av
          det.
        </p>
        <p>
          Derfor blir det i den digitale analyseverden behandlet som et ufravikelig krav for enhver analyseoppsett.
          Imidlertid finnes det også scenarier der det ikke er nødvendig.
        </p>
        <p>
          Derfor vil jeg forklare <Link to="/no/data-lag">hva et data lag er</Link>,{" "}
          dets <Link to="/no/data-lag">fordeler</Link>, og forskjellene mellom{" "}
          <Link to="/no/data-lag">data lag for Google Tag Manager</Link> og{" "}
          <Link to="/no/data-lag">Adobe Launch</Link>.
        </p>
        <p>
          Deretter vil vi se på implementeringen for de mest populære Tag Management Systems (TMS). Jeg vil
          forklare <Link to="/no/data-lag">design</Link>-fasen, etterfulgt av{" "}
          <Link to="/no/data-lag">implementeringen</Link> og <Link to="/no/data-lag">feilsøking</Link>.{" "}
        </p>
        <H as="h2">Hva er et data lag?</H>
        <p>Et data lag er en <b>datastruktur</b> som gir relevant informasjon i nøkkel-verdi-par for bruk med for eksempel, <Link to="/no/tagg-administrasjon">Tag Management Systems</Link>.</p>
        <p>Et data lag er tilgjengelig i det globale JavaScript-området på nettstedet som en <b>Array</b> eller <b>Objekt</b> og holder data i en strukturert form for andre programmer å bruke.</p>
        <p>Fordelen med et data lag ligger i en programmessig enkel tilgang til relevant data under et nettstedbesøk.</p>

        <p>
          Det muliggjør tilgang til data på ett sentralt punkt og er grunnlaget for dataanalyse logikken i et <Link to="/no/tagg-administrasjon">tag management system</Link>.
        </p>
        <SnippetJS caption="Kodeeksempel for å opprette et data lag for Google Tag Manager. Det lager et JavaScript-objekt i en array (!) som inneholder alle relevante dimensjoner for senere dataanalyse og regelopprettelse i tag management systemet.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "kategori side",
  "pageName": "sneaker oversikt",
  "language": "en-US",
}];`}
        </SnippetJS>
        <p>
          Siden det brukes til å lagre data fra flere datakilder, forenkler det overvåkingen av gjeldende dataverdier,
          fordi bare ett enkelt sted må observeres ("single point of truth").
        </p>
        <p>
          Et data lag blir <b>gjenoppbygd ved hver sideinnlasting</b> med data fra den aktuelle websiden og
          muligens andre relaterte data om besøkende og deres besøk.
        </p>
        <p>
          <b>Merk:</b> Enkeltsideapplikasjoner (SPA) laster ikke siden på nytt mellom side navigeringen. Derfor er
          data lag-konfigurasjonen for en enkeltsideapplikasjon forskjellig fra typiske websider med sideinnlastinger.
        </p>
        <p>
          De holdte dataene representerer egenskaper eller funksjoner på en underside og holdes i et nøkkel-verdi-par. Nøklene
          holder beskrivende navn på funksjonene sammenkoblet med en gjeldende verdi, som vanligvis endres under brukerens reise.
        </p>
        <SnippetJS caption="De relevante funksjonene til en underside, som dens kategori, navn og språk er lagret i variabler og kan senere utnyttes for analyse eller regelopprettelse i TMS.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "kategori side", //kategori
  "pageName": "sneaker oversikt", //navn
  "language": "en-US", //språk
}];`}
        </SnippetJS>
        <p>
          Det overordnede målet er å gjøre disse datapunktene tilgjengelige i tag management systemet, slik at de kan sendes
          sammen med dataene som deles med f.eks. Google Analytics eller Facebook Ads for bedre å beskrive interaksjoner på nettstedet.
        </p>
        <p>
          For å muliggjøre denne integrasjonen, holder TMS referanser til nøkkel-verdi-parene og kan for eksempel utføre
          regler når deres verdi endres.
        </p>
        <p>
          <b>Eksempel:</b> En besøkende zoomer inn på et produktbilde og utløser dermed en hendelse "produktzoom". Uten
          ytterligere data sendt sammen med hendelsen, er det ikke veldig innsiktsfullt. Derfor sender vi også data om
          f.eks. produktnavn, kategori og pris, slik at vi kan analysere hendelsen i en meningsfull kontekst.
        </p>
        <p>
          De ekstra dataene ville gjøre det mulig å verifisere om slike produktzooms bare skjer i visse produktkategorier. Hvis ja, kan det være gunstig å legge til flere bilder til andre produkter i samme kategori,
          siden besøkende ser ut til å være veldig interessert i bildedetaljene til disse produktene.
        </p>
        <p>
          Poenget er at vi strukturerer alle relevante data i beskrivende nøkler og verdier og gjør dem tilgjengelige på et sentralt sted der de enkelt kan hentes.
        </p>
        <p>
          Slike datapunkter er vanligvis viktige egenskaper ved sideinnholdet eller en klassifisering vi har kommet opp med for å segmentere besøkende basert på atferd.
        </p>
        <p>
          For å bedre forstå hva et data lag er, som en forenklet visualisering, kan du tenke deg et Excel-ark.
          Arket holder viktige egenskaper om en webside i sin overskrift (sti, språk, kategori,
          innlogget status) sammen med en gjeldende verdi for hvert element.
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Forenklet data lag-eksempel i Excel"
            className="article-img"
            caption="Du kan forestille deg et data lag som en enkel tabell med kolonneoverskriftene "
          />
        </ImgContainerFixed>
        <H as="h2">Hva er fordelene med å bruke et data lag?</H>
        <p>
          Mens en besøkende navigerer gjennom et nettsted, finner mange brukerinteraksjoner sted: klikk på knapper, skjemaer som
          fylles ut eller videoer som sees.
        </p>
        <p>
          Hvis disse interaksjonene gir oss mulighet til å trekke konklusjoner om brukerengasjement, sendes de til f.eks. Google
          Analytics sammen med andre beskrivende data om den besøkende, økten, hendelsen i seg selv eller
          HTML-elementet de interagerte med.
        </p>
        <p>
          <u>Og dette er det avgjørende punktet</u>: De ekstra dataene for å beskrive slike interaksjoner kommer fra forskjellige
          datakilder, for eksempel fra <b>frontend, database</b> eller en ekstern <b>API</b>.
        </p>
        <p>
          For å forstå fordelene med et data lag, må vi først forstå utfordringene som oppstår når vi
          krever data fra flere datakilder.
        </p>
        <p>La oss se på et eksempel der vi samler data fra slike kilder og tenke det gjennom:</p>
        <p>
          <b>Eksempel:</b> Den besøkende kjøper et produkt på en nettside. Følgende dimensjoner kan være av interesse:
        </p>
        <ul>
          <li>produktnavn</li>
          <li>produktpris</li>
          <li>produktstørrelse</li>
          <li>produktkategori</li>
          <li>produktfarge</li>
          <li>handlekurvverdi</li>
          <li>merke</li>
          <li>første kjøp</li>
          <li>kundesegment</li>
          <li>kundens rabatt</li>
          <li>kjønn</li>
          <li>land</li>
        </ul>
        <p>
          Etter kjøpet lander besøkende på en takk-side som viser alle detaljer om kjøpet og leveringsadressen.
        </p>
        <p>
          <b>Frontend</b>: For å sende produktdata, handlekurvverdi og merke sammen med hendelsen, kan vi
          potensielt skrape det fra takk-siden.
        </p>
        <p>
          Hovedutfordringen med å skrape data fra en webside er at dataene må være tilgjengelige på hver side der
          interaksjonen finner sted. Dette er sjelden tilfelle i virkeligheten.
        </p>
        <p>
          Det er tilrådelig å måle så mange av de samme dimensjonene på tvers av alle interaksjoner på et nettsted for å gjøre
          interaksjonene sammenlignbare senere under dataanalysen. Derfor, hvis vi skulle følge denne tilnærmingen, er det sannsynlig
          at andre sider ikke viser <i>produktdata, handlekurvverdi og merke</i> for å sende sammen med
          andre hendelser.
        </p>
        <p>
          Så hvis de nødvendige dataene ikke er tilgjengelige på andre sider, vil vi unngå å legge til alle disse dataene til innholdet
          bare for analytikkens skyld. <b>Derfor bruker vi et data lag</b>. Det gjør dataene tilgjengelige for oss å
          samle inn, uansett om de er synlige på siden eller ikke. Det er bokstavelig talt et lag med data som sitter på toppen av en hvilken som helst
          underside, og serverer de dataene vi trenger.
        </p>
        <p>
          En annen utfordring med å skrape data fra frontend er at det til slutt bryter sammen. Når sider endres
          og disse endringene påvirker HTML-strukturen til de skrapede elementene, vil datainnsamlingen bryte sammen.
          Spesielt i større selskaper skjer det hyppige endringer på sider, og flere team jobber på dem
          uten å vite om noen HTML-elementer trengs for datainnsamling. Derfor vil enhver skraping av data fra
          frontend bryte på et tidspunkt på ofte oppdaterte nettsteder.
        </p>
        <p>
          Et data lag utnytter denne tilnærmingen og gjør det mulig å hente brukerdata på en sikker og samtidig enkel
          måte.
        </p>
        <p>
          <b>Database</b>: Å samle inn kundedata (kundesegment, rabatt, kjønn og første kjøp) kan
          bli litt utfordrende: Kundedata må enten sendes sammen med serverresponsen eller med en
          separat API.
        </p>
        <p>
          Men siden dette er private data, må innsamlingen autentiseres av hensyn til databeskyttelse. Det
          betyr at en API-forespørsel ikke kan håndteres i nettleseren fordi API-nøkkelen ellers ville være tilgjengelig
          for erfarne brukere.
        </p>
        <p>
          Derfor er den beste løsningen å sende dataene sammen med serverresponsen basert på nettstedets
          innloggingsautentisering. <br />
          Når brukeren er logget inn, fylles data laget med de relevante dataene fra databasen. Uten
          innlogging eksponeres ingen sensitive data.
        </p>
        <p>
          <b>API</b>: Geo-data som landet kan hentes fra en ekstern tjeneste-API.
        </p>
        <p>
          Imidlertid oppstår den samme utfordringen som ved henting av data fra databasen: Enhver API-forespørsel fra
          frontend eller tag management system krever en API-nøkkel, som ikke bør håndteres i nettleseren av
          sikkerhetsmessige årsaker.
        </p>
        <p>
          En annen ulempe ved å bruke API-er for datainnsamling, spesielt med hendelser, er tiden det tar før
          dataene kommer tilbake. Hvis en bruker navigerer til en annen side før dataene ankom, risikerer vi å miste
          hendelsen.
        </p>
        <p>La oss oppsummere fordelene raskt:</p>
        <H as="h3">Fordeler</H>
        <ul>
          <li>Data er tilgjengelige uansett om de er synlige på siden</li>
          <li>Robust datainnsamling</li>
          <li>Sikker innsamling av sensitive data</li>
          <li>Reduserer datatap for asynkrone dataforespørsler</li>
        </ul>
        <H as="h2">Hvorfor du sannsynligvis trenger et</H>
        <p>
          Ved å opprette et data lag gjøres et JavaScript-objekt tilgjengelig i det globale området til nettleseren ved hver
          sideinnlasting.
        </p>
        <p>
          Dataene det inneholder kan komme fra databasen din, frontend eller API-er, slik at datainnsamling fra disse kildene
          blir pålitelig, sikker og uavhengig av HTML-en på siden.
        </p>
        <p>
          Data fra databasen kan gjøres tilgjengelige på enhver underside av nettstedet uten mye bryderi uten å være
          synlige i innholdet.
        </p>
        <p>
          Av de ovennevnte årsakene råder jeg generelt klienter til å implementere data lag hvis de er seriøse om sine
          dataanalyseambisjoner. Fordelene med datakvalitet, pålitelighet og de relaterte langsiktige tidsbesparelsene
          rettferdiggjør de høyere implementeringskostnadene.
        </p>
        <p>
          Det endelige målet med webanalyse er å ta datadrevne forretningsbeslutninger, så datakvalitet bør være en
          prioritet.
        </p>
        <p>
          Nå, la oss se på de forskjellige tilgjengelige alternativene og noen implementeringseksempler før vi dykker ned i
          design- og implementeringsfasen.
        </p>
        <H as="h2">Data Lag for Adobe Analytics, DTM, Launch og Tealium</H>
        <p>
          Data lag kan ha forskjellige strukturer. Generelt skiller vi mellom de som kommer med en{" "}
          <b>objektbasert struktur</b> og en <b>array-basert struktur</b>.
        </p>
        <p>
          Ifølge{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            data lag definisjon
          </a>{" "}
          fra World Wide Web Consortium (W3C) følger syntaksen den til et JavaScript-objekt. Det er uoffisielt
          forkortet <b>CEDDL</b> (Customer Experience Digital Data Layer).
        </p>
        <p>Du kan også nestle andre objekter eller arrays i det:</p>
        <SnippetJS caption="Eksempel på et data lag for Adobe Analytics med en objektbasert struktur, etter W3C-definisjonen.">
          {`
window.digitalData = {
  pageName: "sneaker oversikt",
  destinationPath: "/no/sneakers",
  breadCrumbs: ["hjem","sneakers"],
  publishDate: "2020-07-01",
  language: "en-US"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> og Tealium følger CEDDL-strukturen. I eksempelet ovenfor lagrer vi
          data i et objekt kalt <code>digitalData</code>. Navnet er ikke standardisert og kan velges fritt,
          men du må erklære navnet i tag management systemet.
        </p>
        <p>
          For å endre dataene er det flere alternativer (som med ethvert JS-objekt), men den enkleste måten er bare å
          overskrive verdiene:
        </p>
        <SnippetJS caption="For å legge til data i data laget, bare overskriv eksisterende data som med ethvert vanlig JavaScript-objekt.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          Hovedideen med den objektbaserte strukturen er at de lastes inn én gang per sideinnlasting, men de endres ikke
          mye basert på brukerinteraksjon. Dataene er for det meste <b>statisk</b>.
        </p>
        <p>
          Hendelsessporing er ikke drevet av hendelser som går inn i data lag-objektet. Hendelser spores med et{" "}
          <b>separat sporingsbibliotek</b> for å sende dem videre til en analyseplattform, f.eks. Adobe Analytics. Når
          hendelsessporingskoden kjøres, sendes data lag-objektet med i sin helhet og kan brukes
          under dataanalysen.
        </p>
        <SnippetJS caption="Hendelsessporing for Adobe med en objektbasert data lag-struktur håndteres gjennom _satellite-biblioteket.">
          {`
//Hendelse med valgt farge
_satellite.setVar("sneaker farge", "svart");
_satellite.track("velg farge"); 

`}
        </SnippetJS>
        <H as="h2">Bruk Adobe Launch med en array-basert data lag</H>
        <p>
          Du kan enkelt bruke Adobe Launch med en array-basert struktur også. Adobe Launch Extension{" "}
          <b>Data Layer Manager</b> gjør det mulig.
        </p>
        <p>
          Her er noen lenker til flere ressurser for å bruke den array-baserte versjonen med Adobe Launch:
        </p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo av bruk av Data Layer Manager med Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension med{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              dokumentasjon
            </a>
          </li>
        </ul>
        <H as="h2">Data Lag for Google Tag Manager, Matomo og Piwik Pro</H>
        <p>
          <b>Data lag for Google Tag Manager</b>, Matomo og Piwik Pro er array-basert og uoffisielt referert til som
          det hendelsesdrevne data laget <b>(EDDL)</b>.
        </p>
        <p>
          Data håndteres også i objekter, men den overordnede strukturen til GTM data laget er en <b>array med objekter</b>.
        </p>
        <SnippetJS caption="Kodeeksempel for å legge til et GTM data lag">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "kategori side",
  "pageName": "sneaker oversikt",
  "language": "en-US",
}];
`}
        </SnippetJS>
        <p>
          Sporingslogikken med en array-basert struktur er forskjellig: Nye data eller endringer dyttes inn i det via{" "}
          <code>dataLayer.push()</code>. Så en <b>push til data laget</b> kan utløse taggutførelser i tag management systemet.
        </p>
        <p>
          Den grunnleggende forskjellen til en objektbasert struktur er at endringer vanligvis sendes sammen med en
          hendelse og at regler utløses basert på disse endringene uten noe ekstra bibliotek, bare ved å observere
          om data lag-arrayen endres.
          <br />
          Siden ingen andre biblioteker som <code>_satellite</code> er nødvendig, krever vi en avhengighet mindre.
        </p>
        <p>
          En annen karakteristikk ved den array-baserte tilnærmingen er at dataene endres ganske ofte gjennom
          brukerreisen siden enhver brukerinteraksjon kan endre data lag-variablene.
        </p>{" "}
        <p>
          Så et array-basert data lag er grunnlaget for hendelsessporing og håndterer data mer fleksibelt, mens en
          objektbasert en heller fungerer som en statisk datalagring.
        </p>
        <p>
          Gjennom den fleksibiliteten sies en array-basert data lag-struktur å være mer egnet for
          enkeltside-applikasjoner. <br />
          Du kan imidlertid også spore SPAer med et objektbasert data lag, det vil bare kreve noen flere linjer med
          kode og muligens noen kanttilfeller å løse.
        </p>
        <p>
          Hvis du er i starten av et prosjekt og har valget, vil jeg foretrekke et array-basert data lag
          imidlertid.
          <br />
          Å endre en allerede eksisterende oppsett fra en objektstruktur til en array, er imidlertid unødvendig.
        </p>
        <H as="h2">Innholdsstyringssystemer med data lag inkludert</H>
        <p>
          <b>WordPress</b>-brukere har det enkelt siden de kan bruke{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            denne pluginen
          </a>{" "}
          for å implementere Google Tag Manager sammen med et forhåndskonfigurert data lag.
        </p>
        <p>Det fylles automatisk med kategorier, forfatternavn, publiseringsdatoer og søkeord.</p>
        <p>
          Datapunktene kan sjekkes eller avmerkes i plugin-innstillingene. Videre tilbyr pluginen
          forhåndskonfigurerte hendelser for skjemainnsendinger av de mest vanlige skjemapluginnene.
        </p>
        <p>
          Hvis du er en nettbutikkeier og bruker <b>WooCommerce</b> for WordPress, kan du implementere et{" "}
          <b>klassisk e-handelsdata lag</b> samt et <b>avansert e-handelsdata lag</b> med samme
          plugin, som er ganske kraftig.
        </p>
        <p>
          <b>WordPress</b>-brukere som ønsker å bruke <b>Tealium</b> kan bruke en plugin for{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Tealium
          </a>
          .
        </p>
        <p>
          <b>Drupal</b> har også en{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            plugin
          </a>
          .
        </p>
        <p>
          <b>Wix</b> og <b>Squarespace</b>-brukere kan implementere Google Tag Manager gjennom plattformverktøyene, men må
          implementere data lag-koden manuelt.
        </p>
        <H as="h2">Implementering av data lag</H>
        <p>
          Så hvordan implementerer du et data lag? - Siden planlegging og implementering krever kunnskap på tvers av områdene
          digital analyse, frontend-utvikling og backend-utvikling, blir implementeringen vanligvis utført gjennom et webbyrå sammen med en{" "}
          <Link to="/no/google-analytics-konsulent">analyse konsulent</Link>.
        </p>
        <p>
          Analyse konsulenten briefer webbyrået og leder prosjektet til implementeringen er
          vellykket validert. Deretter konfigureres tag management systemet og analyseverktøyene.
        </p>
        <p>
          Hvis du er interessert og kjenner litt til JavaScript, kan du implementere det selv med den følgende
          <b>implementeringsveiledningen</b>.
        </p>
        <p>Implementeringen går gjennom 3 trinn:</p>
        <ol>
          <li>
            <Link to="/no/data-lag">Data Lag Design</Link>
          </li>

          <li>
            <Link to="/no/data-lag">Implementering</Link>
          </li>

          <li>
            <Link to="/no/data-lag">Feilsøking</Link>
          </li>
        </ol>
        <H as="h3">1. Data Lag Design</H>
        <p>
          Designfasen handler om å definere hvilke interaksjoner som skal måles sammen med hvilke dimensjoner.
        </p>
        <p>
          Eventuelle attributter til besøkende, økter, sider, produkter eller hendelser kan være av potensiell interesse under data
          analyse og bør vurderes for data lag-arkitekturen.
        </p>
        <p>
          For å bestemme hva som skal inkluderes, start med slutten i tankene og spør deg selv hvilke forretningskritiske spørsmål
          som må besvares og snevre inn på de relaterte datapunktene.
        </p>
        <p>
          Neste steg er å finne ut hvordan disse datapunktene må struktureres og hvilke dimensjoner som er viktigst å legge til.
        </p>
        <p>
          <b>Eksempel</b>: En språkskole som driver et WordPress-nettsted på flere språk ønsker å vite morsmålet til sine besøkende og hvilket fremmedspråk de er mest interessert i. Målet er å
          potensielt kjøre online annonsering via Facebook Ads som retter seg mot demografier med lignende attributter.
        </p>
        <p>
          Som neste steg må vi definere alle relevante data på tvers av forskjellige typer sider (hjemmeside, kurs
          sider, om oss, kontakt og nyheter). For å forenkle, la oss se på de mest interessante sidene og fokusere på
          forsiden og kurssidene.
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-example-language-school.png"
            alt="Data Lag design eksempel for et språkskole nettsted"
            caption="Eksempel på et data lag design for en språkskole forside og kurssider"
          />
        </ImgContainerFixed>
        <p>
          <b>Eksempel</b>: Array-basert Google Tag Manager data lag for en språkskole
        </p>
        <SnippetJS caption="Kodeeksempel for å initiere et GTM data lag for et språkskoleeksempel. Merk at tall er deklarert som strenger.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": "de", //Språk for UI
  "sessionDuration": "182", //Øktvarighet i sekunder
  "languageIntent": "es", //mest besøkte kurs språk
  "pageType": "kurs side",
  "courseName": "Spansk A1 - Nybegynner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Varighet i uker
}];
`}
        </SnippetJS>
        <p>
          <b>Eksempel</b>: Objektbasert data lag for Adobe Launch
        </p>
        <SnippetJS caption="Initialisering av et data lag for Adobe Launch eller Adobe DTM.">
          {`
window.digitalData = window.digitalData || {
  "language": "de", //Språk for UI
  "sessionDuration": 182, //Øktvarighet i sekunder
  "languageIntent": "es", //mest besøkte kurs språk
  "pageType": "kurs side",
  "courseName": "Spansk A1 - Nybegynner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Varighet i uker
};
`}
        </SnippetJS>
        <H as="h3">2. Implementering</H>
        <p>
          Data lag må implementeres på hver underside av et nettsted. Kodeeksemplene ovenfor viser imidlertid den
          endelige <b>beregnede tilstanden</b>.
        </p>
        <p>
          Under implementeringen må datapunktene først hentes for å beregne deres endelige tilstand, så den
          faktiske kilden kommer til å se litt annerledes ut.
        </p>
        <p>For å gi et realistisk eksempel antar jeg følgende:</p>
        <ul>
          <li>
            Øktvarighet og språkinteresse samles inn gjennom en egendefinert JavaScript og holdes i
            nettleserens lokale lagring.
          </li>
          <li>
            Språk, sidetype og kursdata kan hentes fra databasen via serverresponsen og gjøres
            tilgjengelig på kursskjemaene og forsiden.
          </li>
        </ul>
        <p>Kildekoden til data laget i backend i henhold til de ovennevnte forutsetningene vil se slik ut:</p>
        <SnippetJS caption="Eksempel kildekode for et data lag før beregning.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
        </SnippetJS>
        <H as="h4">Hendelsessporing med data lag push</H>
        <p>For å sende hendelser til et GTM data lag kan du bruke push-metoden og bokstavelig talt dytte hendelser inn i det.</p>
        <SnippetJS caption="Data lag push eksempel for hendelsessporing">
          {`
window.dataLayer.push({
  "event": "course-booking", 
  "startWeek": "24"
});
`}
        </SnippetJS>
        <p>
          <b>event</b> nøkkelordet er et spesielt nøkkelord og kan adresseres som en <b>egendefinert hendelse</b> fra GTM
          containeren.
        </p>
        <p>
          Tag management systemet observerer data laget og utfører en tag så snart en forhåndsdefinert egendefinert hendelse er
          sendt til det.
        </p>
        <p>Etter at en hendelse er lagt til, kan TMS for eksempel sende en hendelse til Google Analytics.</p>
        <p>
          Alle relevante data for å gi kontekst (navn, språk, språknivå, kursvarighet) er tilgjengelige og kan
          sendes sammen med hendelsen, for eksempel startuken for kurset.
        </p>
        <p>
          I et objektbasert data lag vil den samme hendelsen bli sendt direkte til adobe analytics via deres eget hendelsessporingsbibliotek.
        </p>
        <p>For Adobe Launch vil eksempel koden se slik ut:</p>
        <SnippetJS caption="Adobe Launch eksempel for hendelsessporing. Merk at dataene sendes direkte til Adobe Analytics uten å gå gjennom data laget først">
          {`
_satellite.setVar("startWeek", 24);
_satellite.track("course-booking");
`}
        </SnippetJS>
        <H as="h4">Kodeplassering i kildekoden</H>
        <p>
          Data lag-koden bør legges til i <code>{`<head>`}</code> på siden før tag management systemet.
        </p>
        <p>
          På grunn av denne rekkefølgen sikrer du at den allerede er beregnet når tag management systemet vil ha tilgang til den.
        </p>
        <p>
          <b>Eksempel</b>: Plassering i kildekoden
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="plassering av data laget før TMS tag i kildekoden"
          caption="Data laget bør plasseres i <head> seksjonen før tag management systemet, for å sikre at dataene allerede er lastet når TMS vil ha tilgang til dem."
        />
        <H as="h3">3. Feilsøking</H>
        <p>
          De vanligste prosedyrene for å feilsøke et data lag er å simulere sideinnlastinger eller hendelser for å verifisere at alle datapunktene fylles med de riktige dataene.
        </p>
        <p>
          Siden det er globalt tilgjengelig i nettleserkonsollen, kan du enkelt skrive ut alle verdiene til konsollen
          (forutsatt at standard navnekonvensjoner er brukt):
        </p>
        <p>
          <b>Nettsteder med GTM</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Nettsteder med Adobe Launch eller DTM</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium</b>
        </p>
        <p>
          <code>utag.data</code> eller <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager har til og med sin egen <strong>Debugger Mode</strong>. Du kan aktivere den fra GTM
          grensesnittet ved å klikke på <b>preview</b> øverst til høyre.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-activate-debugger-mode.png"
          alt="Skjermbilde av Google Tag Manager som viser knappen for å aktivere feilsøkingsmodus"
          caption={`Du kan aktivere GTMs feilsøkingsmodus ved å klikke på forhåndsvisningsknappen. Hvis du deretter besøker nettstedet ditt med den installerte GTM-containeren, vil feilsøkingsvinduet dukke opp nederst i nettleseren.`}
        />
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="skjermbilde av GTM feilsøkingsvinduet"
          caption="Du kan inspisere alle data lag-variablene og se deres gjeldende verdier mens du interagerer med nettstedet. I tillegg kan du overvåke sekvensen av hendelser som også registreres. Klikk på hver hendelse for å se hvilke endringer hendelsen forårsaket."
        />
        <p>
          Hvis du ikke har tilgang til tag management containeren, men vil feilsøke det likevel, kan du bruke en{" "}
          <b>chrome extension</b>.
        </p>
        <H as="h4">Data Lag Chrome Extensions</H>
        <p>
          Det finnes en rekke potensielle utvidelser for feilsøking der ute. Jeg foretrekker de som støtter de fleste
          leverandører, så jeg ikke trenger å bytte mellom utvidelser når jeg feilsøker et annet nettsted.
        </p>
        <p>Følgende chrome-utvidelser er for øyeblikket mine favoritter for feilsøking:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Utvidelse basert på{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            og open source. Den har ganske god ytelse og støtter GTM, DTM, Tealium{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              og mange flere
            </a>
            .
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - En annen allrounder med støtte for Adobe Analytics (DTM & Launch), samt Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              og mer
            </a>
            .
          </li>
        </ul>
        <H as="h4">Chrome-utvidelser for feilsøking av Google Analytics og GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - etter å ha aktivert utvidelsen vil det være en ny fane tilgjengelig i Chrome DevTools når du åpner dem
            (F12 på Windows og CTRL+SHIFT+i på Mac). Alle relevante data vises i en grafisk UI og den oppdateres
            mens du surfer på et nettsted.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - logger alle relevante datapunkter til nettleserkonsollen. Aktiver "preserve log" i konsollinnstillingene,
            for å beholde logger på tvers av sidenavigasjon.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - definitivt utvidelsen med det kuleste navnet og logoen og min tidligere favoritt. Det er open source
            med et enkelt oppsett og fungerer også med Adobe DTM. <br />
            <u>
              Fjern avmerkingen for “use three-column layout where available”, “show GA Classic tags” og “show Floodlight tags” i
              utvidelsesinnstillingene,
            </u>{" "}
            ellers blir loggene litt rotete.
          </li>
        </ul>
        <H as="h4">Chrome-utvidelser for feilsøking av Adobe Analytics, Launch og DTM</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - lar deg laste staging- eller produksjonsbiblioteket til tag management systemet og kan aktivere
            feilsøkingsmodus.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger for Adobe Analytics</b>
            </a>{" "}
            - aktiverer feilsøkingsmodus. Alternativt kan du også skrive <code>_satellite.setDebug(true)</code> inn i
            konsollen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - meta utvidelse for å feilsøke alle Adobe-produkter.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - etterfølgeren til Experience Cloud Debugger som tilbyr bedre oversikt (for øyeblikket fortsatt i beta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - enkle tabeller med alle gjeldende verdier.
          </li>
        </ul>
        <H as="h2">E-handel Data Lag</H>
        <p>
          Data lag for e-handel er mer omfattende og deres struktur er mer kompleks. De må inneholde mer
          data og håndtere flere hendelser.
        </p>
        <p>Det er derfor planlegging og implementering av et e-handelsnettsted tar betydelig mer tid.</p>
        <p>
          E-handelsrapporten i Google Analytics, for eksempel, viser ingen data hvis implementeringen ikke
          følger deres{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=de">
            e-handelsdata lag dokumentasjon
          </a>
          .
        </p>
        <p>
          Store e-handelsbutikker krever imidlertid enda mer avanserte sporingsoppsett. De implementerer et{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            data lag for avansert e-handel
          </a>
          , som muliggjør enda mer funksjonalitet i Google Analytics.
        </p>
        <p>
          Du må følge implementeringsretningslinjene for Google Analytics nøye for at e-handelsrapportene skal fungere. Det gjelder strukturen til data laget og også variabelnavn.
        </p>
        <p>
          Hvis du velger en annen analyseplattform for e-handel, står du fritt til å planlegge strukturen som du vil.
        </p>
        <H as="h2">Når er et data lag ikke nødvendig?</H>
        <p>
          Som med alt annet, er det også tilfeller der den ekstra innsatsen for å implementere et data lag ikke er
          berettiget.
        </p>
        <p>
          I eksemplene ovenfor så vi på tilfeller der vi hentet data fra forskjellige datakilder (Frontend,
          Backend, API) og løste problemer som oppsto ved å jobbe med en rekke datakilder.
        </p>
        <p>
          Mange nettsteder (såkalte brosjyre-nettsteder) har imidlertid verken en innloggingsfunksjon eller en database.
        </p>
        <p>
          En annen viktig faktor er hvor ofte endringer implementeres på nettstedet. Mange nettsteder sjelden gjennomgår
          innholdet sitt eller legger til funksjonalitet regelmessig. Jeg ser til og med at bedrifter driver enkle brosjyre-nettsteder
          med omtrent 50 undersider og et kontaktskjema som den vanskeligste konverteringen.
        </p>
        <p>
          Siden slike nettsteder sannsynligvis bare krever data fra frontend for å gjøre sin dataanalyse, kan de klare seg
          med et enkelt analyseoppsett uten noe data lag. Det vil ikke gjøre innsamlingsdelen mye mer robust
          eller sikrere, og dermed reduseres fordelene. Under slike omstendigheter rettferdiggjør ikke fordelene den ekstra innsatsen ved implementering.
        </p>
        <p>
          Typiske eksempler på når et data lag ikke er nødvendig, er brosjyresider eller innholdssider med et
          begrenset antall eller nesten ingen harde konverteringer. Vanligvis er slike eiere av nettsteder bare interessert i
          å kategorisere brukerengasjementet etter deres innholdseksjoner eller noen interne bedriftsklassifikasjoner.
        </p>
        <p>
          Slike krav kan oppnås med litt avansert JavaScript og et gjennomtenkt system for strukturering
          av innholdet.
        </p>
        <p>
          Så snart datainnsamlingen fra frontend regelmessig bryter sammen og definitivt når en database skal
          involveres, anbefales et data lag.
        </p>
        <p>
          Alternative løsninger er ofte bare midlertidig tilfredsstillende, på grunn av stadig økende analyseambisjoner
          og regelmessig sammenbrudd av datainnsamlingen. I tillegg er alle tilpassede løsninger vanligvis vanskelige å overlevere til
          et annet byrå.
        </p>
        <p>
          Et data lag har gode sjanser til å overleve tidens prøve fordi det allerede er et etablert konsept i
          webanalysebransjen, så webbyråer har økende erfaring med å implementere og vedlikeholde et.
        </p>
        <H as="h2">Konklusjon</H>
        <p>
          Et data lag er gullstandarden for profesjonelle analyseoppsett. Det øker datakvaliteten og dermed
          forbedrer dataanalysen som helhet, samtidig som det oppfyller sikkerhetskrav.
        </p>
        <p>
          For ambisiøse nettstedseiere som ønsker å begynne med seriøs dataanalyse, er det den enkleste og mest
          robuste løsningen.
        </p>
        <p>
          Hvis du har valget, implementer en array-basert struktur, siden du har færre avhengigheter og kan bruke den
          på alle typer nettsteder.
        </p>
        <p>
          Innholdssider er imidlertid så begrenset i funksjonalitet og vanligvis bare med et begrenset antall harde
          konverteringer, så et data lag kan potensielt neglisjeres. Dette gjelder spesielt hvis alle nødvendige data
          er tilgjengelige på sidene eller kan gjøres tilgjengelige gjennom noen omveier.
        </p>
        <p>
          Hvis du vil implementere et selv, er det sannsynligvis enklest å gjøre det med et WordPress-nettsted. Eventuelle
          avanserte krav er imidlertid sannsynligvis ikke verdt tiden og risikoen for et utilfredsstillende resultat.
          <br />
          Derfor er det vanligvis veien å gå å implementere med hjelp av en analyse konsulent, siden det sparer
          tid og unngår unødvendige risikoer.
        </p>
        <p>
          Jeg anbefaler deg å installere en av de nevnte chrome-utvidelsene for å inspisere data lagene til noen større
          nettsteder der ute. Det er vanligvis en stor inspirasjon og gir noen interessante KPIer å potensielt
          integrere i dine egne analyseoppsett 😉.
        </p>
        <H as="h2">Data Lag dokumentasjon fra forskjellige TMS-leverandører</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Initiering og endring av data
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/no/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Initiering
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              endring av data
            </a>
          </li>

          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              endring av data
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Initiering
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              endring av data
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Hva er et data lag eksempel?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Et data lag eksempel er gitt i artikkelen. Et JavaScript-objekt lagrer data fra en nettside, database eller en ekstern kilde på en sentral, fleksibel og lett tilgjengelig måte. Et eksempel på kode for å initiere et data lag for Google Tag Manager er: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"kategori side\", \"pageName\": \"sneaker oversikt\", \"language\": \"en-US\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "Hva er data lag variabler?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data lag variabler er nøkkel-verdi-par innenfor data laget som lagrer spesifikke informasjonsbiter. Disse variablene kan inkludere sideegenskaper, brukeradferdsdata og mer, og tjener som et sentralt dataregister for analyse og sporing."
              }
            },
            {
              "@type": "Question",
              "name": "Hvorfor bruke et data lag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Et data lag er essensielt for robust, fleksibel og sikker datainnsamling. Det sentraliserer data fra forskjellige kilder, gjør det lett tilgjengelig og konsistent på tvers av ulike websider og brukerinteraksjoner. Denne tilnærmingen forbedrer datakvalitet og pålitelighet, som er avgjørende for datadrevne beslutninger."
              }
            },
            {
              "@type": "Question",
              "name": "Trenger jeg et data lag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Selv om det ikke alltid er nødvendig, anbefales et data lag generelt for de som er seriøse med sine dataanalyseambisjoner. Det gir datakvalitet, pålitelighet og langsiktige tidsbesparelser som rettferdiggjør den høyere implementeringsinnsatsen."
              }
            },
            {
              "@type": "Question",
              "name": "Hva er fordelene med et data lag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Fordelene med et data lag inkluderer: Tilgjengelighet av data uavhengig av om det er synlig på siden. Robust datainnsamling. Redusering av datatap for asynkrone dataforespørsler. Sikker datainnsamling fra flere kilder."
              }
            },
            {
              "@type": "Question",
              "name": "Har alle nettsteder et data lag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ikke alle nettsteder har et data lag. Implementeringen avhenger av kompleksiteten til nettstedet og dybden av dataanalysen som kreves. Enkle nettsteder har kanskje ikke et data lag, mens mer komplekse nettsteder, spesielt de som fokuserer på datadrevne beslutninger, sannsynligvis vil ha det."
              }
            },
            {
              "@type": "Question",
              "name": "Hvordan får jeg tilgang til data laget?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data laget er globalt tilgjengelig i nettleserkonsollen. For nettsteder med Google Tag Manager kan du få tilgang til det ved å bruke dataLayer eller Object.assign(...dataLayer). For Adobe Launch eller DTM kan du få tilgang til det ved å bruke digitalData."
              }
            },
            {
              "@type": "Question",
              "name": "Hvordan dyttes data inn i data laget?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "For å dytte data inn i data laget, bruker du dataLayer.push() metoden. For eksempel: window.dataLayer.push({ \"event\": \"course-booking\", \"startWeek\": \"24\" }); Denne metoden brukes til å legge til nye data eller endringer i data laget. Event-nøkkelordet kan brukes til å utløse en annen tag utførelse i tag management systemet."
              }
            }
          ]
        }
        } />
        <H as="h2">Ytterligere ressurser</H>
        <ul>
          <li>
            Simo Ahava om{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              data laget i GTM
            </a>{" "}
            og{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              hvordan håndtere data i det.
            </a>
          </li>
          <li>Les min <Link to="/no/google-tag-manager-oppsett">Google Tag Manager veiledning</Link> og lær hvordan du setter det opp.</li>
          <li>
            Kevin Haags presentasjon fra Measurecamp Berlin 2019 om{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer i Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default DataLayer;
